import * as XLSX from 'xlsx';
export const Host = window.location.hostname == "localhost" ? "http://localhost:6001" : "https://apimaptx.pxa.co.id";

export const api = function (url, dd) {
    dd.Token = localStorage.getItem("TokenData");
    try {
        return new Promise((resolve, reject) => {
            fetch(encodeURI(Host + url), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dd),
            }).then(response => response.json()).then(hasil => {
                resolve(hasil);
            }).catch((error) => {
                reject(error)
            });
        });
    } catch (e) {
        console.log(e);
    }
}

export const saiki = function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

export const tanggalIndo = function (data) {
    var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let hasil = [year, month, day].join('-');

    if (hasil == "0000-00-00" || hasil == null) {
        return (hasil);
    } else {
        let BulanIndo = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"];

        let tahun = hasil.substring(2, 4);
        let bulan = hasil.substring(5, 7);
        let tgl = hasil.substring(8, 10);

        let result = `${tgl} ${BulanIndo[bulan - 1]} ${tahun}`;
        return result;
    }
}

export const numberFormat = function (ini) {
    if (ini != null) {
        var formatter = new Intl.NumberFormat("en-GB", { 
            style: "decimal", 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 2 
        });
        var nmr = 0;
        if (isNaN(ini)) {
            nmr = 0;
        } else {
            nmr = parseFloat(ini);
        }
        return formatter.format(nmr);
    }
}

export function terbilang(angka) {
    var bilangan = [
        '',
        'satu',
        'dua',
        'tiga',
        'empat',
        'lima',
        'enam',
        'tujuh',
        'delapan',
        'sembilan'
    ];

    var satuan = ['', 'ribu', 'juta', 'miliar', 'triliun'];

    function konversi(bilangan) {
        var puluhan = [
            '',
            'sepuluh',
            'dua puluh',
            'tiga puluh',
            'empat puluh',
            'lima puluh',
            'enam puluh',
            'tujuh puluh',
            'delapan puluh',
            'sembilan puluh'
        ];

        var belasan = [
            '',
            'sebelas',
            'dua belas',
            'tiga belas',
            'empat belas',
            'lima belas',
            'enam belas',
            'tujuh belas',
            'delapan belas',
            'sembilan belas'
        ];

        if (bilangan < 10) {
            return bilangan !== 0 ? bilangan : '';
        } else if (bilangan < 20) {
            return belasan[bilangan - 10];
        } else {
            var satuanPuluh = Math.floor(bilangan / 10);
            var satuanSatu = bilangan % 10;
            return puluhan[satuanPuluh] + ' ' + (satuanSatu !== 0 ? bilanganToTerbilang(satuanSatu) : '');
        }
    }

    function bilanganToTerbilang(bilangan) {
        if (bilangan === 0) return '';
        else return bilangan <= 9 ? bilangan[bilangan] : konversi(bilangan);
    }

    if (typeof angka !== 'number') {
        return 'Input harus berupa angka';
    }

    var bilanganString = angka.toString().split('.');
    var bilanganUtama = parseInt(bilanganString[0], 10);
    var bilanganPecahan = parseInt(bilanganString[1], 10) || 0;

    var terbilangUtama = '';
    var terbilangPecahan = '';

    if (bilanganUtama === 0) {
        terbilangUtama = 'nol';
    } else {
        var groups = [];
        while (bilanganUtama > 0) {
            groups.push(bilanganUtama % 1000);
            bilanganUtama = Math.floor(bilanganUtama / 1000);
        }

        for (var i = 0; i < groups.length; i++) {
            if (groups[i] !== 0) {
                terbilangUtama =
                    bilanganToTerbilang(groups[i]) + ' ' + satuan[i] + ' ' + terbilangUtama;
            }
        }
    }

    if (bilanganPecahan > 0) {
        terbilangPecahan = 'koma ' + bilanganToTerbilang(bilanganPecahan);
    }

    return terbilangUtama + (terbilangPecahan !== '' ? ' ' + terbilangPecahan : '');
}

export function pembilang(nilai) {
    nilai = Math.floor(Math.abs(nilai));

    var simpanNilaiBagi = 0;
    var huruf = [
        '',
        'Satu',
        'Dua',
        'Tiga',
        'Empat',
        'Lima',
        'Enam',
        'Tujuh',
        'Delapan',
        'Sembilan',
        'Sepuluh',
        'Sebelas',
    ];
    var temp = '';

    if (nilai < 12) {
        temp = ' ' + huruf[nilai];
    } else if (nilai < 20) {
        temp = pembilang(Math.floor(nilai - 10)) + ' Belas';
    } else if (nilai < 100) {
        simpanNilaiBagi = Math.floor(nilai / 10);
        temp = pembilang(simpanNilaiBagi) + ' Puluh' + pembilang(nilai % 10);
    } else if (nilai < 200) {
        temp = ' Seratus' + pembilang(nilai - 100);
    } else if (nilai < 1000) {
        simpanNilaiBagi = Math.floor(nilai / 100);
        temp = pembilang(simpanNilaiBagi) + ' Ratus' + pembilang(nilai % 100);
    } else if (nilai < 2000) {
        temp = ' Seribu' + pembilang(nilai - 1000);
    } else if (nilai < 1000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000);
        temp = pembilang(simpanNilaiBagi) + ' Ribu' + pembilang(nilai % 1000);
    } else if (nilai < 1000000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000000);
        temp = pembilang(simpanNilaiBagi) + ' Juta' + pembilang(nilai % 1000000);
    } else if (nilai < 1000000000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000000000);
        temp =
            pembilang(simpanNilaiBagi) + ' Miliar' + pembilang(nilai % 1000000000);
    } else if (nilai < 1000000000000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000000000000);
        temp = pembilang(nilai / 1000000000000) + ' Triliun' + pembilang(nilai % 1000000000000);
    }

    return temp;
}

export async function exportData(sql = [], title = "Export Data", field = false) {
    let arr = [];
    let header = [];
    for (let th of field) header.push(th.cap);

    arr.push(header);
    for (let i in sql) {
        let data = sql[i];
        var innerRowData = [];
        for (let b in field) {
            let td = field[b];
            innerRowData.push(data[td.sort]);
        }
        arr.push(innerRowData);
    }
    var filename = title + ".xlsx";
    var ws_name = "Data";
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, filename);
}